<template>
  <div class="text-white modal">
    <div
      class="px-4 py-8 bg-gray-700 border-b border-gray-800 rounded-tl-lg rounded-tr-lg sm:px-6"
    >
      <p
        class="text-base font-semibold leading-6 tracking-wide text-center text-indigo-600 uppercase"
      >
        Welcome to Previz
      </p>
      <h1
        class="my-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-slate-100 sm:text-4xl sm:leading-10"
      >
        Setup your world
      </h1>
    </div>

    <div class="p-6 rounded-b-lg bg-zinc-900 modal-content">
      <template v-if="show === 'overview'">
        <div class="m-6">
          <div
            class="overflow-hidden bg-gray-400 divide-y divide-gray-200 rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0"
          >
            <div
              @click.prevent="show = 'drive'"
              class="relative p-6 rounded-tr-lg bg-zinc-800 sm:rounded-tr-none group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            >
              <div>
                <span
                  class="inline-flex p-3 text-teal-700 bg-[#A6FFEA] rounded-lg ring-4 ring-white"
                >
                  <fa-icon icon="cube" class="fa-fw" />
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <a href="#" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Import 3D Model From Drive
                  </a>
                </h3>
                <p class="mt-2 text-sm text-gray-200">
                  Ready to import your 3D model? Simply upload and import from
                  Disguise Drive to get started with Previz. Then you can add
                  assets, previzualise, edit and share your 3D environments for
                  review.
                </p>
              </div>
              <span
                class="absolute text-gray-300 pointer-events-none top-6 right-6 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
                  />
                </svg>
              </span>
            </div>

            <div
              @click.prevent="show = 'demo'"
              class="relative p-6 bg-zinc-800 sm:rounded-tr-lg group focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            >
              <div>
                <span
                  class="inline-flex p-3 text-purple-700 rounded-lg bg-[#E6CCFA] ring-4 ring-white"
                >
                  <fa-icon icon="bolt" class="fa-fw" />
                </span>
              </div>
              <div class="mt-8">
                <h3 class="text-lg font-medium">
                  <a href="#" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    Start With A Demo Scene
                  </a>
                </h3>
                <p class="mt-2 text-sm text-gray-200">
                  Do you want to use an existing 3D model to get started? Check
                  out one of the demo scenes to try Previz to see how it works.
                  Play with existing files to start learning how to build your
                  own project.
                </p>
              </div>
              <span
                class="absolute text-gray-300 pointer-events-none top-6 right-6 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  class="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-if="show === 'demo'">
        <div class="px-8 pt-2">
          <a
            href="#"
            @click.prevent="show = 'overview'"
            class="text-blue-500 cursor-pointer hover:underline"
          >
            <fa-icon icon="arrow-left" class="mr-2" /> Back</a
          >

          <div class="px-2 overflow-y-auto h-[56vh]">
            <ul
              role="list"
              class="grid grid-cols-2 mt-4 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8"
            >
              <li class="relative" v-for="scene in demoscenes" :key="scene.id">
                <a
                  href="#"
                  @click.prevent="onDemoSceneSelect(scene)"
                  class="block w-full overflow-hidden bg-gray-100 rounded-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                >
                  <img
                    :src="scene.cover"
                    alt=""
                    class="object-cover pointer-events-none group-hover:opacity-75"
                  />
                  <button
                    type="button"
                    class="absolute inset-0 focus:outline-none"
                  >
                    <span class="sr-only"
                      >Create Previz scene with the
                      {{ scene.title }} template</span
                    >
                  </button>
                </a>
                <a
                  href="#"
                  @click.prevent="onDemoSceneSelect(scene)"
                  class="block mt-2 text-sm font-medium truncate pointer-events-none text-slate-100 cursor-"
                >
                  {{ scene.title }}
                </a>
                <p
                  class="block text-sm font-medium text-gray-200 pointer-events-none"
                >
                  {{ scene.description }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template v-if="show === 'drive'">
        <a
          href="#"
          @click.prevent="show = 'overview'"
          class="text-blue-500 cursor-pointer hover:underline"
        >
          <fa-icon icon="arrow-left" class="mr-2" /> Back</a
        >

        <div>
          <iframe
            id="first_run_drive"
            class="w-full min-h-50vh"
            :src="driveModalUrl('scene', 'select', 'return_first_scene')"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import iframeCommunication from '@mixins/iframe-communication-mixin.js'

export default {
  mixins: [iframeCommunication],

  data() {
    return {
      show: 'overview'
    }
  },

  computed: {
    demoscenes() {
      return this.$store.getters['librarycontent/demoscenes']
    },

    needsOnboarding() {
      // This is here to prevent a race condition, where this modal can appear
      // before the store knows if the user needs onboarding or not. It's used
      // to close the modal if the user does need onboarding.
      return this.$store.getters['doesUserNeedOnboardingPreviz']
    }
  },

  mounted() {
    window.addEventListener('message', this.messageEventListener)

    if (this.needsOnboarding) {
      this.$emit('close')
    }
  },

  destroyed() {
    window.removeEventListener('message', this.messageEventListener)
  },

  methods: {
    onSelect(item) {
      let file = {
        src: item.src,
        name: item.title
      }
      this.$bus.$emit('scene:model:import', file)
      this.$emit('close')
    },
    async onDemoSceneSelect(scene) {
      const resp = await fetch(scene.src).then((resp) => resp.json())
      this.$bus.$emit('scene:load', resp.data)
      this.$emit('close')
    },
    messageEventListener(event) {
      if (event.data.event === 'return_first_scene') {
        this.$store
          .dispatch('assets/loadAsset', {
            id: event.data.assetId,
            force: true
          })
          .then((asset) => {
            let file = {
              resource_id: asset.id,
              src: asset.url,
              name: asset.title
            }
            this.$bus.$emit('scene:model:import', file)
          })
        this.$emit('close')
      }
    }
  },

  watch: {
    needsOnboarding() {
      if (this.needsOnboarding) {
        this.$emit('close')
      }
    }
  }
}
</script>
